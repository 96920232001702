.home__img{
    width: 100%;
    border-radius: 5px;
}
.home__card{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    width: 178px;
}
.home__card .subtitle{
    text-align: center;
}
@media (max-width: 1024px) {
    .home__card{
        width: 156px;
    }
    .home__card .subtitle{
        font-size: 17px;
        font-weight: 600;
    }
}
@media (max-width: 876px) {
    .home__card{
        width: 120px;
    }
    .home__card .subtitle{
        font-size: 12px;
        font-weight: 600;
    }
}
@media (max-width: 425px) {
    .home__card{
        width: 100px;
    }
}
@media (max-width: 375px) {
    .home__card{
        width: 90px;
    }
}
