.paragraph{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
@media (max-width: 576px) {
    .paragraph{
        row-gap: 15px;
    }
}
@media (max-width: 425px) {
    .paragraph{
        row-gap: 10px;
    }
}