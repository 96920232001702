.languages{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.languages .link{
    text-align: center;
}
.languages .active{
    border: 0;
}