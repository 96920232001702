.privacyPolicy__content{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 10px;
}
@media (max-width: 576px) {
    .privacyPolicy__content{
        row-gap: 20px;
    }
}
@media (max-width: 425px) {
    .privacyPolicy__content{
        row-gap: 10px;
    }
}